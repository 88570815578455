import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-modal-routing';
import { getPublishedTime } from './utils';

export const formatNumber = (number) => (number < 10 ? '0' + number : number);

const calcHeight = (width) => width / 0.5622;

const COVER_WIDTH = 262.5;

export const UNSPLASH_IMAGES_URL = 'https://images.unsplash.com/';

const MUST_SHOW_TITLE = process.env.GATBSY_SHOW_TITLE === 'true';
const MUST_SHOW_DATE = process.env.GATBSY_SHOW_DATE === 'true';

const onMouseOut = (event) => {
    event.target.pause();
    event.target.currentTime = 0;
};

const onMouseOver = (event) => {
    if (event.target.readyState === 4) {
        event.target.play();
    }
};

const DisplayCover = ({ thumbnailAsset, thumbnailType, url }) => {
    //By default for the moment we set an image
    const [isLoaded, setIsLoaded] = useState(false);

    return thumbnailType && thumbnailType.includes('video/') ? (
        <CoverVideo
            src={thumbnailAsset.includes(UNSPLASH_IMAGES_URL) ? thumbnailAsset : url + thumbnailAsset}
            width="100%"
            height="100%"
            loop
            preload="metadata"
            hidden={!isLoaded}
            muted={true}
            onLoadedData={() => {
                setIsLoaded(true);
            }}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
        />
    ) : thumbnailType && thumbnailType.includes('image/') ? (
        <CoverImg
            onLoad={() => {
                setIsLoaded(true);
            }}
            hidden={!isLoaded}
            src={thumbnailAsset.includes(UNSPLASH_IMAGES_URL) ? thumbnailAsset : url + thumbnailAsset}
            alt="Cover page"
            width="100%"
            height="100%"
        />
    ) : (
        <EmptyCard />
    );
};
const Card = ({ title, lastPublishDate, thumbnailAsset, thumbnailType, url, posterObjects }) => {
    const date = getPublishedTime(lastPublishDate);

    const safeSelectLargePoster = () => posterObjects && posterObjects.large && posterObjects.large.relativeKey;
    const selectImage = () => {
        return process.env.GATSBY_COVER_AS_POSTER === 'true'
            ? safeSelectLargePoster(posterObjects) || thumbnailAsset
            : thumbnailAsset;
    };

    return (
        <CardArticle>
            <CardSubContainer>
                <DisplayCover thumbnailAsset={selectImage()} thumbnailType={thumbnailType} url={url} />
                {(MUST_SHOW_TITLE || MUST_SHOW_DATE) && <CardFooter title={title} lastPublish={date} />}
            </CardSubContainer>
        </CardArticle>
    );
};

const CardFooter = ({ title, lastPublish }) => (
    <FooterDiv>
        {MUST_SHOW_TITLE && <TitleStoryDiv>{title}</TitleStoryDiv>}
        {MUST_SHOW_DATE && <DateStoryDiv>{lastPublish}</DateStoryDiv>}
    </FooterDiv>
);

export const HomeCardTable = ({ list }) => {
    return (
        <MainSection id="homepage-list">
            {list.map((elem) => (
                <Link key={elem._id} to={`/story/${elem.slug}`} asModal state={{ storyUrl: elem.url }}>
                    <Card {...elem} />
                </Link>
            ))}
        </MainSection>
    );
};

HomeCardTable.propTypes = {
    list: PropTypes.array,
};
export const size = {
    tablet: '950px',
    small: '550px',
};
export const device = {
    tablet: `(max-width:${size.tablet})`,
    small: `(max-width:${size.small})`,
};
const CardArticle = styled.div`
    display: flex;
    flex: 1;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin: 0 1.5rem 3rem 1.5rem;
    @media ${device.tablet} {
        margin: 0 1rem 2rem 1rem;
    }
    @media ${device.small} {
        margin: 0 1rem 2rem 1rem;
    }
    transition: all 0.3s ease 0s;
    &:hover {
        transform: scale(1.03);
    }
`;

const CardSubContainer = styled.div`
    width: calc(calc(100vw - 6rem) / 2);
    height: calc(calc(calc(100vw - 6rem) / 2) / 0.5622);
    max-width: ${COVER_WIDTH}px;
    max-height: ${calcHeight(COVER_WIDTH)}px;
    min-width: ${COVER_WIDTH / 3}px;
    min-height: ${calcHeight(COVER_WIDTH / 3)}px;

    position: relative;
    box-shadow: rgba(0, 0, 0, 0.8) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    z-index: 1;
    overflow: hidden;
    border-width: 0px;
    border-style: solid;
    border-color: white;
    border-image: initial;
    border-radius: 14px;

    background: linear-gradient(287deg, rgb(150, 150, 150), rgb(240, 240, 240));
    background-size: 400% 400%;

    -webkit-animation: animationBackground 2s ease infinite;
    -moz-animation: animationBackground 2s ease infinite;
    animation: animationBackground 2s ease infinite;

    @-webkit-keyframes animationBackground {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @-moz-keyframes animationBackground {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
    @keyframes animationBackground {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
`;

const TitleStoryDiv = styled.div`
    position: relative;
    font-weight: 700;
    font-style: bold;
    color: #ffffff;
    font-size: 2.4rem;
    font-height: 2.4rem;
    @media ${device.tablet} {
        font-size: 2rem;
        font-height: 2rem;
    }
    @media ${device.small} {
        font-size: 1.6rem;
        font-height: 1.6rem;
    }
`;

const DateStoryDiv = styled.div`
    position: relative;
    font-weight: 400;
    color: #ffffff;
    font-size: 1.2rem;
    margin-top: 20px;
    @media ${device.tablet} {
        margin-top: 15px;
        font-size: 1.1rem;
        font-height: 1.1rem;
    }
    @media ${device.small} {
        margin-top: 10px;
        font-size: 1rem;
        font-height: 1rem;
    }
`;
const FooterDiv = styled.div`
    position: absolute;
    left: 0;
    padding: 50px 20px 50px 20px;
      @media ${device.tablet} {
         padding: 30px 15px;
    }
    @media ${device.small} {
         padding: 15px 10px;
    }
    bottom: 0;
    z-index: 4;
    width:100%;
    min-height:20%
    color: #ffffff;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0) 100%);
`;

const MainSection = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const CoverAnimation = `
    -webkit-animation: fadein 700ms; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 700ms; /* Firefox < 16 */
    -ms-animation: fadein 700ms; /* Internet Explorer */
    -o-animation: fadein 700ms; /* Opera < 12.1 */
    animation: fadein 700ms;

    @keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Firefox < 16 */
    @-moz-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Safari, Chrome and Opera > 12.1 */
    @-webkit-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Internet Explorer */
    @-ms-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    /* Opera < 12.1 */
    @-o-keyframes fadein {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const CoverImg = styled.img`
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 3;
    ${CoverAnimation};
`;

const EmptyCard = styled.div`
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 3;
    background-color: #ddd;
    ${CoverAnimation};
`;

const CoverVideo = styled.video`
    object-fit: cover;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 3;

    -webkit-transition: opacity 600ms, visibility 600ms linear;
    transition: opacity 600ms, visibility 600ms linear;
    ${CoverAnimation};
`;
